import React from "react"

import "./css/index.scss"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"

const IndexPage = () => (
  <Layout>
    <SEO title="QueroPedir" description="Conheça a politica de privacidade do nosso aplicativo." keywords={[`politica`, `privacidade`, `quero pedir`]} />
    <Header siteTitle="QueroPedir">
      <h1>Política de Privacidade</h1>
    </Header>
    <div className="container become-partner">
      <section><h1>Termos e Condições</h1>
        <p className="strong">Por favor, leia com atenção os termos e condições. Ao se cadastrar no <a
          href="https://www.queropedir.com.br/">queropedir.com.br</a> você ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS do
          Website/Aplicativo. Note
          que a recusa destes Termos do Website/Aplicativo impedirá que você faça pedidos de produtos do nosso
          Website/Aplicativo.</p>
        <h3>1. SERVIÇOS OFERECIDOS</h3>
        <p><span>1.1</span> Este TERMO se aplica para
          regular o uso do serviço oferecido pelo <a href="https://www.queropedir.com.br/">queropedir.com.br</a> aos
          USUÁRIOS,
          qual seja, possibilitar a escolha, pelos USUÁRIOS, de RESTAURANTES cadastrados e, via on-line, efetivar
          solicitações
          para aquisição (e entrega em domicílio e retirada no local e pedido no local) de gêneros alimentícios
          fornecidos
          pelos RESTAURANTES, de acordo com o cardápio disponibilizado, sendo possível, igualmente, aos USUÁRIOS,
          a efetivação do pagamento do preço dos produtos via on-line.</p>
        <p><span>1.2</span> O serviço do <a href="https://www.queropedir.com.br/">queropedir.com.br</a> consiste,
          portanto, em aproximar, através do nosso site, os USUÁRIOS e os RESTAURANTES cadastrados,
          possibilitando que os USUÁRIOS encaminhem, aos RESTAURANTES, pedidos de gêneros alimentícios, bem como,
          sendo essa a opção dos USUÁRIOS, receber on-line pagamento do preço dos produtos entregues aos USUÁRIOS
          pelos RESTAURANTES.</p>
        <p><span>1.3</span> Desde logo fica esclarecido
          ao USUÁRIO - o qual se declara ciente - que o serviço oferecido pelo <a
            href="https://www.queropedir.com.br/">queropedir.com.br</a>
          se relaciona apenas à intermediação (com opção de pagamento on-line) para comercialização de produtos
          alimentícios, não abarcando preparo, embalagem, disponibilização e entrega física (via motoboy ou outros
          meios) dos
          produtos, sendo esses quatro itens de responsabilidade integral do RESTAURANTE, a quem deverão ser
          direcionados
          quaisquer reclamos acerca de problemas decorrentes de vício, defeito ou inexecução da feitura, preparo e
          entrega de
          produtos alimentícios.</p>
        <h3>2. CADASTRO</h3>
        <p><span>2.1</span> O USUÁRIO, para utilizar os
          serviços acima descritos, deverá ter capacidade jurídica para atos civis e deverá, necessariamente, prestar as
          informações exigidas no CADASTRO, assumindo integralmente a responsabilidade (inclusive cível e criminal) pela
          exatidão e veracidade das informações fornecidas no CADASTRO, que poderá ser verificado, a qualquer momento,
          pelo
          queropedir.com.br <a href="https://www.queropedir.com.br/">queropedir.com.br</a>.</p>
        <p><span>2.1.1</span> Em caso de informações
          incorretas, inverídicas ou não confirmadas, bem assim na hipótese da negativa em corrigi-las ou enviar
          documentação
          que comprove a correção, o QueroPedir se reserva o direito de não concluir o cadastramento em curso ou, ainda,
          de bloquear o cadastro já existente, impedindo o USUÁRIO de utilizar os serviços on-line até que, a critério
          do
          <a href="https://www.queropedir.com.br/">queropedir.com.br</a>, a situação de anomalia esteja regularizada. O
          <a href="https://www.queropedir.com.br/">queropedir.com.br</a> se reserva o direito de impedir, a seu
          critério,
          novos CADASTROS, ou cancelar os já efetuados, em caso de ser detectada anomalia que, em sua análise, seja
          revestida
          de gravidade ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todos os
          USUÁRIOS. Também agirá o <a href="https://www.queropedir.com.br/">queropedir.com.br</a> de tal forma caso
          verifique descumprimento, pelo USUÁRIO, de qualquer obrigação prevista no presente TERMO.</p>
        <p><span>2.2</span> Efetuado, com sucesso, o
          CADASTRO, o USUÁRIO terá acesso aos serviços por meio de login e senha, dados esses que se compromete a não
          divulgar
          a terceiros, ficando sob sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o
          uso de
          login e senha de sua titularidade.</p>
        <h3>3. OBRIGAÇÕES DO USUÁRIO</h3>
        <p><span>3.1</span> Efetuado com sucesso o
          CADASTRO do USUÁRIO, este se obriga a não divulgar a terceiros login e senha de acesso, nem permitir o uso de
          tais
          informações por terceiros, responsabilizando-se pelas consequências do uso de login e senha de sua
          titularidade.</p>
        <p><span>3.2</span> É obrigação do USUÁRIO
          fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente
          (em
          todas as searas jurídicas) por todo o conteúdo por si informado no item CADASTRO, mantendo atualizado e
          confirmado o
          endereço para entrega dos produtos encomendados.</p>
        <p><span>3.3</span> O USUÁRIO se obriga,
          também, a pagar integralmente o preço dos produtos por si solicitados ou encomendados ao RESTAURANTE e
          efetivamente
          a si entregues, seja pela modalidade on-line, seja por qualquer outra forma, diretamente ao portador dos
          produtos
          encomendados por meio deste site (dinheiro, cheque, tickets, etc.).</p>
        <p><span>3.4</span> O USUÁRIO que seja menor de
          18 anos de idade está ciente de que não poderá encomendar e adquirir, em qualquer hipótese, produtos
          alcoólicos,
          responsabilizando-se pela correta informação de sua idade no item CADASTRO.</p>
        <p><span>3.5</span> O USUÁRIO concorda com o
          uso das informações de avaliações e feedbacks do serviços dos RESTAURANTES e do QueroPedir, conforme descrito
          nos TERMOS
          DE PRIVACIDADE do QueroPedir.</p>
        <h3>4. OBRIGAÇÕES DO <a
          href="https://www.queropedir.com.br/">queropedir.com.br</a>
        </h3>
        <p><span>4.1</span> Disponibilizar no site
          <a href="https://www.queropedir.com.br/">queropedir.com.br</a> espaço virtual que permita ao USUÁRIO
          devidamente
          cadastrado efetivar pedidos de compra de gêneros alimentícios anunciados e comercializados pelos RESTAURANTES
          e,
          também, disponibilizar ao USUÁRIO meios de pagamento do preço dos produtos on-line.</p>
        <p><span>4.2</span> Proteger, por meio de
          armazenamento em servidores ou quaisquer outros meios magnéticos de alta segurança, a confidencialidade de
          todas as
          informações e cadastros relativos aos USUÁRIOS, assim como valores atinentes às operações financeiras advindas
          da
          operacionalização dos serviços previstos no presente TERMO. Contudo, não responderá pela reparação de
          prejuízos que
          possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de
          segurança, consigam acessar essas informações.</p>
        <h3>5. MODIFICAÇÕES DESTE TERMO</h3>
        <p><span>5.1</span> O presente TERMO DE USO
          poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com
          vistas
          ao aprimoramento dos serviços disponibilizados.</p>
        <p><span>5.2</span> As novas condições entrarão
          em vigência assim que sua veiculada no site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos
          termos
          modificados, desde que o faça por escrito, através do site <a
            href="https://www.queropedir.com.br/">queropedir.com.br</a>
          , o que gerará o cancelamento de seu CADASTRO.</p>
        <h3>6. CANAL DE COMUNICAÇÃO</h3>
        <p><span>6.1</span> Para estabelecer contato
          entre <a href="https://www.queropedir.com.br/">queropedir.com.br</a> queropedir.com.bre o USUÁRIO fica
          disponibilizado o
          endereço eletrônico deste link, sendo certo que o USUÁRIO se obriga, igualmente, a manter em seu cadastro
          endereço
          eletrônico atual por intermédio do qual se farão as comunicações a ele dirigidas pelo <a
            href="https://www.queropedir.com.br/">queropedir.com.br</a>, desde logo emprestando-se validade jurídica e
          efetividade a
          esse meio eletrônico de troca de informações recíprocas.</p>
        <h3>7. ACEITAÇÃO DO TERMO DE USO</h3>
        <p><span>7.1</span> O USUÁRIO declara ter lido,
          entendido e que aceita todas as regras, condições e obrigações estabelecidas no presente TERMO.</p>
        <h3>8. FORO DE ELEIÇÃO</h3>
        <p><span>8.1</span> As partes elegem como
          competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do
          presente
          TERMO o foro da Comarca do São Paulo - SP.</p>
        <p><i>Última atualização: 08 de julho de 2019</i></p></section>
    </div>
  </Layout>
)

export default IndexPage
